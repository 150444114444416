import React, { useState, useEffect, createRef, useRef } from 'react';

import { match } from 'react-router-dom';
import styled from 'styled-components';
import { Subscribe } from 'unstated';
import { Helmet } from 'react-helmet';

import api from 'api';
import Header from 'components/common/header';
import authContainer from 'containers/auth';
import {
	BREAKPOINT_MOBILE_PX,
	BREAKPOINT_TABLET_PX,
	BREAKPOINT_DESKTOP_PX,
} from 'constants/breakPoints';
import theme from 'config/theme';
import { ProgressBar } from './styled-subcomponents/ProgressBar';
import { Cross } from 'lib/Cross';
import { Button } from 'lib/Button';
import { SubTitle } from './styled-subcomponents/SubTitle';
import { PersonalInfo } from './steps/personal-info/PersonalInfo';
import { FundingInfo } from './steps/leasing-arrangements/FundingInfo';
import { AdditionalInfo } from './steps/AdditionalInfo';
import VerifyMobile, {
	MobileVerifyFlowState,
	initialMobileVerifyState,
} from './steps/mobile-verify/VerifyMobile';
import { Success } from './steps/Success';
import { History } from 'history';
import { SelectOption } from 'lib/Select';
import { notifySuccess, notifyError, notifyFailure } from 'lib/Notifications';
import { EventType } from 'constants/events';
import {
	BookingProfile,
	UserType,
	FundingType,
	Listing,
	LeaseType,
	NoInstitutionData,
	Campus,
	Institution,
} from 'models/listing';
import {
	analyticsService,
	EventName,
	LoginMethod,
	PageName,
	EnquiryTrackingSteps,
	EventParameters,
} from 'services/analyticsService';
import modalContainer from 'containers/modal';
import userContainer from 'containers/user';
import { ModalType } from 'constants/modalTypes';
import listingService from 'services/listingService';
import LeftPanel from './side-panels/LeftPanel';
import RightPanel from './side-panels/RightPanel';
import { PageMeta } from 'constants/page_meta';
import { ReactComponent as InfoIconCirc } from 'assets/images/icons/info-circle-outline.svg';
import { featureFlagContainer, FeatureFlag } from 'containers/featureFlags';
import { enquiryInfo } from './config/enquiryInfo';
import { Step, stepTitles, StepStatus, fullStepSequence } from './config/steps';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import routes from 'config/routes';
import useRouter from 'hooks/useRouter';
import { QueryParamKey } from 'components/inbox/types';

const meta = PageMeta.getStatic('enquiry_flow');

const { colors, headerHeight, mobileHeaderHeight, zIndices } = theme;

const mobileTopBarHeight = '60px';

const PageContainer = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	border-top: 1px solid ${colors.gray};
	overflow-y: auto;
	@media (min-width: ${BREAKPOINT_MOBILE_PX}px) {
		overflow-y: visible;
		top: ${mobileHeaderHeight};
	}
	@media (min-width: ${BREAKPOINT_TABLET_PX + 1}px) {
		top: ${headerHeight};
	}
`;

// Need this as we don't want the header to show on mobile but can't use display: none; or opacity: 0; else the modals won't display
const HeaderContainer = styled.div`
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		header {
			margin-top: -100px;
		}
	}
`;

const PanelsContainer = styled.div`
	display: flex;
	height: 100%;
`;

const CenterPanel = styled.div`
	margin: auto;
	width: 55%;
	flex: 1;
	border-left: 1px solid ${colors.gray};
	border-right: 1px solid ${colors.gray};
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const CenterPanelTopBar = styled.div`
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		height: ${mobileTopBarHeight};
		min-height: ${mobileTopBarHeight};
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 24px;
		transition: all 0.2s;
	}
`;

const CenterPanelContentContainer = styled.div`
	width: 100%;
	margin: 32px auto;
	padding: 0px 16px;
	overflow-y: auto;
	flex: 1;
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		overflow-y: visible;
	}
	@media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
		padding: 0 40px;
	}
`;

const CenterPanelContent = styled.div`
	margin: 0 auto;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		margin-bottom: 88px;
	}
`;

const BackButton = styled(SubTitle)<{ isDisabled?: boolean }>`
	margin: 0;
	width: 100px;
	height: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-tap-highlight-color: transparent;
	opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
	cursor: pointer;
`;

const ActionBar = styled.div<{ greyBackground?: boolean }>`
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 8px 16px;
	border-top: 1px solid ${colors.gray};
	background: ${({ greyBackground }) => (greyBackground ? colors.gray : 'transparent')};

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		padding: 16px 24px;
	}

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		position: fixed;
		bottom: 0;
		background: ${colors.white};
	}
`;

const MobileOnly = styled.div`
	display: block;
	@media (min-width: ${BREAKPOINT_MOBILE_PX}px) {
		display: none;
	}
`;

const DesktopOnly = styled.div`
	display: none;
	@media (min-width: ${BREAKPOINT_MOBILE_PX}px) {
		display: block;
	}
`;

const MobileHeader = styled.div`
	position: fixed;
	top: 0;
	width: 100vw;
	background-color: ${colors.background};
	z-index: ${zIndices.footer};
`;

const MobileHeaderSpacing = styled.div`
	height: ${mobileTopBarHeight};
`;

interface BookingProps {
	history: History;
	match: match<{
		listingId: string;
		propertyId: string;
		roomId?: string;
	}>;
}

const getStepEventType = (step: Step): string => {
	switch (step) {
		case Step.PersonalInfo:
			return EventType.BookingProfilePersonalInfo;
		case Step.FundingInfo:
			return EventType.BookingProfileFunding;
		case Step.MobileVerify:
			return EventType.BookingMobileVerify;
		case Step.AdditionalInfo:
			return EventType.BookingEnquiryAdditionalInfo;
		default:
			return '';
	}
};

// TODO: Try move these into component so all params are redily avaiable
const calculateStepStatus = (
	step: Step,
	bookingProfile: BookingProfile,
	leaseTerm: number,
	moveInDate: string,
	additionalInfo: string,
	selectedRoomId: string,
	firstName: string,
	lastName: string,
	email: string,
	mobileVerified: boolean,
	leaseType: string,
	campusSet: boolean,
	additionalInfoSeen: boolean,
): StepStatus => {
	if (step === Step.PersonalInfo) {
		if (!(firstName && lastName && email && bookingProfile.occupant_type)) {
			return StepStatus.Incomplete;
		}
		if (bookingProfile.occupant_type === UserType.Student) {
			if (!bookingProfile.institution || (!bookingProfile.campus && !campusSet)) {
				return StepStatus.Incomplete;
			}
		}

		if (bookingProfile.occupant_type === UserType.YoungProfessional) {
			if (!bookingProfile.occupation) {
				return StepStatus.Incomplete;
			}
		}
	}
	if (step === Step.FundingInfo) {
		if (!bookingProfile.payment_provider) {
			return StepStatus.Incomplete;
		}
		if (leaseType === LeaseType.SingleRoom && !selectedRoomId) {
			return StepStatus.Incomplete;
		}
		if (!leaseTerm || !moveInDate) {
			return StepStatus.Incomplete;
		}
	}
	if (step === Step.AdditionalInfo) {
		if (!additionalInfo || !additionalInfoSeen) {
			return StepStatus.Incomplete;
		}
	}
	if (step === Step.MobileVerify) {
		if (!mobileVerified) {
			return StepStatus.Incomplete;
		}
	}
	return StepStatus.Complete;
};

const calculateStepSequence = (isMobileVerified: boolean): Step[] => {
	let stepSequence = fullStepSequence;
	if (isMobileVerified) {
		stepSequence = stepSequence.filter(step => step !== Step.MobileVerify);
	}
	return stepSequence;
};

const defaultTenantMessage =
	'Hello, I am interested in this property. Please contact me on WhatsApp so that we can arrange a viewing.';

const Booking = ({ history, match }: BookingProps) => {
	const [currentStep, setCurrentStep] = useState<Step>(Step.Unknown);
	const [stepSequence, setStepSequence] = useState<Step[]>(fullStepSequence);
	const [progressPercentage, setProgressPercentage] = useState<number>(0);
	const [bookingProfile, setBookingProfile] = useState<BookingProfile>({});
	const [savedBookingProfile, setSavedBookingProfile] = useState<BookingProfile>({});
	const [leaseTerm, setLeaseTerm] = useState(0);
	const [moveInDate, setMoveInDate] = useState('');
	const [additionalInformation, setAdditionalInformation] = useState(defaultTenantMessage);
	const [listing, setListing] = useState<Listing>();
	const [institutionOptions, setInstitutionOptions] = useState<SelectOption[]>([]);
	const [campusOptions, setCampusOptions] = useState<SelectOption[]>([]);
	const [campusSet, setCampusSet] = useState<boolean>(false);
	const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [stepStatuses, setStepStatuses] = useState<{ [step: number]: StepStatus }>({});
	const [selectedRoomId, setSelectedRoomId] = useState<string>('');
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [isEmailReadonly, setIsEmailReadonly] = useState<boolean>(false);
	const [mobileVerified, setMobileVerified] = useState<boolean>(false);
	const [mobileVerifyFlowState, setMobileVerifyFlowState] = useState<MobileVerifyFlowState>(
		initialMobileVerifyState,
	);
	const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState<boolean>(false);
	const [originallyLoggedIn, setOriginallyLoggedIn] = useState<boolean>(false);
	const [originallyVerified, setOriginallyVerified] = useState<boolean>(false);
	const [personalInfoSeen, setPersonalInfoSeen] = useState<boolean>(false);
	const [additionalInfoSeen, setAdditionalInfoSeen] = useState<boolean>(false);
	const [favouriteListings, setFavouriteListings] = useState<Listing[]>([]);
	const [acceptedMarketingCheckbox, setAcceptedMarketingCheckbox] = useState<boolean>(false);
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [goTo] = useRouter();
	const [absaCheckbox, setAbsaCheckbox] = useState(false);

	useEffect(() => {
		analyticsService.trackPage(PageName.Enquiry);
		const { listingId } = match.params;
		const isLoggedIn = authContainer.state.token;

		Promise.all([
			api.searchV2.digs(listingId),
			isLoggedIn ? api.userV2.getBookingProfile() : Promise.resolve(),
			isLoggedIn ? api.userV2.getProfile() : Promise.resolve({}),
		])
			.then(responses => responses.map((response: any) => response && response.data))
			.then(([listing, bookingProfilePayload = {}, profilePayload]) => {
				setListing(listing);

				if (listing.rooms.length === 1) {
					setSelectedRoomId(listing.rooms[0].uuid);
				}
				setSavedBookingProfile({ ...bookingProfilePayload });

				if (profilePayload) {
					setAcceptedPrivacyPolicy(true);
					setFirstName(profilePayload.first_name || '');
					setLastName(profilePayload.last_name || '');
					setEmail((profilePayload.email && profilePayload.email.email) || '');
					setMobileVerified(
						(profilePayload && profilePayload.cellphone && profilePayload.cellphone.verified) ||
							false,
					);
					setIsEmailReadonly(!!profilePayload.email && !!profilePayload.email.email);
				}
				setCurrentStep(Step.PersonalInfo);
			});
		api.userV2.getInstitutions().then(({ data }) => {
			setInstitutionOptions(
				data.map((item: { name: string; id: string }) => ({
					label: item.name,
					value: item.id,
				})),
			);
		});

		if (authContainer.state.token) {
			api.favouritesV2
				.getFavourites()
				.then(res => res.data)
				.then(data => {
					setFavouriteListings(data);
				})
				.catch(err => console.log(err));
		}

		if (authContainer.state.token) {
			api.bookings.createDraftBooking(listingId).then(() => {});
		}
		// TODO: Fix and test
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setBookingProfile(savedBookingProfile);
		if (savedBookingProfile.absa_consent_date) {
			setAbsaCheckbox(true);
		}
		if (savedBookingProfile.institution) {
			setInstitutionOptions([
				...institutionOptions,
				{
					label: savedBookingProfile.institution.name,
					value: savedBookingProfile.institution.id,
				},
			]);
			//TODO: MAYBE TEST FOR CAMPUSES HERE AND IF NOT USE BOOKING PROFILE.
			setCampusOptionsFromInstitute(savedBookingProfile.institution.name);
		}
		// TODO: Fix and test
		// eslint-disable-next-line
	}, [savedBookingProfile]);

	useEffect(() => {
		setProgressPercentage(calculateProgressPercentage(currentStep, stepSequence));
		const stepStatuses = stepSequence.reduce(
			(acc, step) => ({
				...acc,
				[step]: calculateStepStatus(
					step,
					savedBookingProfile,
					leaseTerm,
					moveInDate,
					additionalInformation,
					selectedRoomId,
					firstName,
					lastName,
					email,
					mobileVerified,
					listing ? listing.lease_type : '',
					campusSet,
					additionalInfoSeen,
				),
			}),
			{},
		);
		setStepStatuses(stepStatuses);
		// TODO: Fix and test
		// eslint-disable-next-line
	}, [
		currentStep,
		stepSequence,
		savedBookingProfile,
		selectedRoomId,
		mobileVerified,
		firstName,
		lastName,
		email,
	]);

	useEffect(() => {
		if (!listing) {
			return;
		}
		setStepSequence(calculateStepSequence(authContainer.state.token && mobileVerified));
	}, [listing, mobileVerified]);

	useEffect(() => {
		const eventName = getStepEventType(currentStep);
		if (currentStep === Step.PersonalInfo || currentStep === Step.FundingInfo) {
			api.events.send({
				event_type: eventName,
				meta: bookingProfile,
			});
		}

		if (currentStep === Step.AdditionalInfo) {
			api.events.send({
				event_type: eventName,
				meta: match.params.roomId
					? {
							property_uuid: match.params.propertyId,
							room_uuid: match.params.roomId,
					  }
					: { property_uuid: match.params.propertyId },
			});
		}
		// TODO: Scroll to top of center panel
		// TODO: Fix and test
		// eslint-disable-next-line
	}, [currentStep]);

	useEffect(() => {
		const userLoggedIn = Boolean(authContainer.state.token);
		const stepStatus = calculateStepStatus(
			currentStep,
			savedBookingProfile,
			leaseTerm,
			moveInDate,
			additionalInformation,
			selectedRoomId,
			firstName,
			lastName,
			email,
			mobileVerified,
			listing ? listing.lease_type : '',
			campusSet,
			additionalInfoSeen,
		);
		if (currentStep === Step.PersonalInfo) {
			setOriginallyLoggedIn(userLoggedIn);
			setOriginallyVerified(mobileVerified);
		}

		if (
			currentStep !== Step.Success &&
			currentStep !== Step.Unknown &&
			stepStatus === StepStatus.Complete &&
			personalInfoSeen
		) {
			return;
		}

		let enquiryStepName: EnquiryTrackingSteps;
		switch (currentStep) {
			case Step.Unknown:
				enquiryStepName = EnquiryTrackingSteps.EnquiryStart;
				break;
			case Step.PersonalInfo:
				enquiryStepName = EnquiryTrackingSteps.EnquiryPersonalInfo;
				setPersonalInfoSeen(true);
				break;
			case Step.FundingInfo:
				enquiryStepName = EnquiryTrackingSteps.EnquiryFundingInfo;
				break;
			case Step.AdditionalInfo:
				enquiryStepName = EnquiryTrackingSteps.EnquiryAdditionalInfo;
				setAdditionalInfoSeen(true);
				break;
			case Step.MobileVerify:
				enquiryStepName = EnquiryTrackingSteps.EnquiryMobileVerify;
				break;
			case Step.Success:
				enquiryStepName = EnquiryTrackingSteps.EnquirySuccess;
				break;
			default:
				enquiryStepName = EnquiryTrackingSteps.UndefinedEnquiryPage;
		}

		const analyticsParameters: EventParameters = {
			enquiry_step: enquiryStepName,
			logged_in: userLoggedIn,
			digs_listing_uuid: listing?.uuid,
		};

		if (userLoggedIn) {
			analyticsParameters.is_favourite = favouriteListings.some(
				({ uuid }) => uuid === listing?.uuid,
			);
		}

		if (currentStep !== Step.Unknown) {
			analyticsParameters.mobile_verified = mobileVerified;
		}

		if (currentStep > Step.PersonalInfo) {
			analyticsParameters.originally_logged_in = originallyLoggedIn;
		}

		if (currentStep > Step.MobileVerify) {
			analyticsParameters.originally_verified = originallyVerified;
		}

		analyticsService.trackEvent(EventName.EnquiryEvent, {
			property_type: listing?.sub_type || '',
			listing_type: listing?.lease_type || '',
			price: listing?.price || '',
			price_currency: listing?.currency?.symbol || '',
			city: listing?.location?.city || '',
			suburb: listing?.location?.suburb || '',
			country: 'ZA',
			...analyticsParameters,
		} as any);
		// eslint-disable-next-line
	}, [currentStep]);

	const calculateProgressPercentage = (step: Step, stepSequence: Step[]): number => {
		const current = stepSequence.indexOf(step) + 1;
		// Minus 1 as the last step should show a complete bar
		return Math.round((current / (stepSequence.length - 1)) * 100);
	};

	const hasCampuses = (institute: any) => {
		if (institute['campuses'] != null || institute.campus) {
			return true;
		} else {
			return false;
		}
	};

	const setCampusOptionsFromInstitute = (selectedInstitution: any) => {
		api.userV2.getInstitutions(selectedInstitution).then(({ data }) => {
			if (Object.keys(data).length !== 0) {
				if (hasCampuses(data[0])) {
					const institutionCampuses = data[0]['campuses'].map(
						(item: { id: any; name: string }) => ({
							value: item.id,
							label: item.name,
						}),
					);
					setCampusOptions([...institutionCampuses, { label: "I'm not sure yet", value: null }]);
				}
			} else {
				if (savedBookingProfile.campus) {
					setCampusOptions([
						{
							label: savedBookingProfile.campus.name,
							value: savedBookingProfile.campus.id,
						},
					]);
				}
			}
		});
	};

	const saveBookingProfile = (bookingProfile: BookingProfile): Promise<any> => {
		let cleanedObject = {} as any;
		for (const [key, value] of Object.entries({
			...bookingProfile,
			absa_consent_date: absaCheckbox ? new Date() : undefined,
		})) {
			if (value || value === false) {
				cleanedObject[key] = value;
			}
			if (value === undefined) {
				cleanedObject[key] = null;
			}
		}
		return api.userV2.updateBookingProfile(cleanedObject);
	};

	const getSavedBookingProfile = (): Promise<any> => {
		return api.userV2.getBookingProfile();
	};

	const submitBooking = (): void => {
		if (!listing) {
			return;
		}
		setIsLoading(true);
		saveBookingProfile(bookingProfile)
			.then(response => {
				setSavedBookingProfile(response.data);
				if (response.status === 400 || response.data.status.status === 'Invalid') {
					notifyError('Booking profile invalid');
					setIsLoading(false);
					return Promise.reject();
				}
				return api.bookings.createBooking({
					booking_information:
						match.params.roomId || selectedRoomId
							? {
									...listing.booking_information,
									room_uuid: match.params.roomId || selectedRoomId,
							  }
							: listing.booking_information,
					occupancy_start_date: moveInDate,
					occupancy_duration: leaseTerm,
					additional_information: additionalInformation || null,
					attached_documents: (bookingProfile.documents || []).map(({ uuid }) => ({ uuid })),
				});
			})
			.then(response => {
				setIsLoading(false);
				if (response.status !== 201) {
					api.events.send({
						event_type: EventType.BookingEnquirySubmitFail,
						meta: response.data,
					});
					analyticsService.trackEvent(EventName.EnquiryEvent, {
						enquiry_step: EnquiryTrackingSteps.EnquiryFailed,
					});
					if (response.data && response.data.user) {
						notifyFailure(`Error: ${response.data.user}`);
						return;
					}
					notifyFailure('Failed to submit booking enquiry');
					return;
				}

				getSavedBookingProfile().then(response => {
					setSavedBookingProfile(response.data);
				});

				api.events.send({
					event_type: EventType.BookingEnquirySubmitSuccess,
					meta: response.data,
				});
				analyticsService.trackEvent(EventName.EnquirySubmit);
				if (featureFlagContainer.isEnabled(FeatureFlag.InboxRedirect)) {
					goTo(`${routes.inbox}?${QueryParamKey.OpenFirstMessage}=true`);
				} else {
					notifySuccess('Booking enquiry successfully submitted');
				}
				setCurrentStep(stepSequence[stepSequence.length - 1]);
			});
	};

	const createUserInstitution = async (userInstitutionData: NoInstitutionData) => {
		await api.userV2
			.createUserInstitution({
				country: userInstitutionData.country,
				institution: {
					name: userInstitutionData.institution_name,
				},
				first_name: firstName || 'Anonymous',
				last_name: lastName || 'Anonymous',
				email: email || 'no-email@none.com',
			})
			.then(response => {
				if (response.status < 200 || response.status > 300) {
					notifyError('There was an issue, please contact support.');
					return;
				}
				if (response.data.message == 'Institution already exists') {
					notifyError(response.data.message);
					return;
				}
				handleInstitutionFieldChange(
					{
						institution: {
							id: response.data.institution.id,
							name: response.data.institution.name,
						},
						campus: {
							id: response.data.campus.id,
							name: response.data.campus.name,
						},
					},
					true,
				);

				setInstitutionOptions([
					...institutionOptions,
					{ value: response.data.institution.id, label: response.data.institution.name },
				]);
				setCampusOptions([
					{
						value: response.data.campus.id,
						label: response.data.campus.name,
					},
				]);
				notifySuccess(response.data.message);
			});
	};

	const handleInstitutionSearch = (value?: string) => {
		api.userV2.getInstitutions(value).then(({ data }) => {
			setInstitutionOptions(
				data.map((item: { name: string; id: string; search_terms: string[] }) => ({
					label: item.name,
					value: item.id,
					searchTerms: item.search_terms,
				})),
			);
		});
	};

	const navigateBackToListing = (): void => {
		const { listingId } = match.params;
		history.push(`/digs/view/${listingId}`);
		modalContainer.set('');
	};

	const handleUserTypeSelection = (type: UserType): void => {
		setBookingProfile(bookingProfile => ({
			...bookingProfile,
			occupant_type: type,
			payment_provider: undefined,
		}));
	};

	const handleFundingTypeSelection = (type: FundingType): void => {
		setBookingProfile(bookingProfile => ({
			...bookingProfile,
			payment_provider: type,
		}));
	};

	const handleBackClick = (): void => {
		if (currentStep === Step.MobileVerify && mobileVerifyFlowState.step === 2) {
			setMobileVerifyFlowState(state => ({
				...state,
				step: 1,
			}));
			return;
		}
		const currentIndex = stepSequence.indexOf(currentStep);
		if (currentIndex === 0) {
			return;
		}
		setCurrentStep(stepSequence[currentIndex - 1]);
	};

	const handleTermsAcceptedClick = () => {
		setAcceptedPrivacyPolicy(true);
		handleNextClick(true);
	};

	const setContainerScroll = () => {
		scrollableContainerRef.current!.scrollTop = 0;
		hidingHeader.current!.style.minHeight = mobileHeaderHeight;
		hidingHeader.current!.style.height = mobileHeaderHeight;
		hidingHeader.current!.style.opacity = '1';
	};

	const handleNextClick = async (termsAccepted?: boolean) => {
		if (isLoading) {
			return;
		}

		if (featureFlagContainer.isEnabled(FeatureFlag.TCsBug)) {
			if (currentStep === Step.PersonalInfo && !acceptedPrivacyPolicy) {
				notifyError('Privacy policy checkbox is required');
				setShowValidationErrors(true);
				return;
			}
		} else {
			if (currentStep === Step.PersonalInfo && !termsAccepted && !acceptedPrivacyPolicy) {
				modalContainer.set(ModalType.termsAndConditions, {
					onTermsAcceptedClick: handleTermsAcceptedClick,
				});
			}
		}

		if (currentStep === Step.AdditionalInfo) {
			if (
				additionalInformation &&
				additionalInformation.length <= 500 &&
				additionalInformation.length >= 10
			) {
				submitBooking();
			} else if (!additionalInformation) {
				notifyError('Please enter a message for the landlord');
				setShowValidationErrors(true);
			} else if (additionalInformation.length < 10) {
				notifyError('Please enter a message more than 10 characters for the landlord');
				setShowValidationErrors(true);
			} else {
				notifyError('Please enter a message less than 500 characters for the landlord');
				setShowValidationErrors(true);
			}
			return;
		}

		const currentIndex = stepSequence.indexOf(currentStep);

		const currentStepStatus = calculateStepStatus(
			currentStep,
			bookingProfile,
			leaseTerm,
			moveInDate,
			additionalInformation,
			selectedRoomId,
			firstName,
			lastName,
			email,
			mobileVerified,
			listing ? listing.lease_type : '',
			campusSet,
			additionalInfoSeen,
		);

		if (currentStep === Step.PersonalInfo && currentStepStatus === StepStatus.Incomplete) {
			notifyError('Please fill in all required fields');
			setShowValidationErrors(true);
			setContainerScroll();
			return;
		}
		if (currentStep === Step.FundingInfo && currentStepStatus === StepStatus.Incomplete) {
			notifyError('Please fill in all required fields');
			setShowValidationErrors(true);
			setContainerScroll();
			return;
		}
		if (currentStep === Step.PersonalInfo) {
			setIsLoading(true);
			if (!authContainer.state.token) {
				const recaptchaToken =
					featureFlagContainer.isEnabled(FeatureFlag.RecaptchaNoSignup) && executeRecaptcha
						? await executeRecaptcha('nosignup')
						: '';
				const response = await api.auth.registerAnonymousUser(
					email,
					firstName,
					lastName,
					acceptedMarketingCheckbox || false,
					recaptchaToken,
					'nosignup',
				);
				if (response.status !== 201) {
					setIsLoading(false);
					if (response.data.hasOwnProperty('email') && response.data.email.length) {
						notifyError(response.data.email[0]);
						modalContainer.set(ModalType.Login, {
							onLoginSuccess: () => {
								// TODO: Update state correctly
								window.location.reload();
							},
						});
						return;
					}
					notifyError('An error occurred');
					return;
				}
				setIsEmailReadonly(true);
				await authContainer.set({
					token: response.data.token,
				});
				if (authContainer.state.token) {
					const { listingId } = match.params;
					api.bookings.createDraftBooking(listingId).then(() => {});
				}

				const profileResponse = await api.userV2.getProfile();

				analyticsService.trackEvent(EventName.Signup, { auth_method: LoginMethod.Default });
				analyticsService.trackEvent(EventName.SetUser, {
					digs_user_id: profileResponse.data.uuid,
				});

				await userContainer.set(profileResponse.data);
			} else {
				await api.userV2.updateProfile({
					first_name: firstName,
					last_name: lastName,
				});
				const profileResponse = await api.userV2.getProfile();
				await userContainer.set(profileResponse.data);
			}
			setIsLoading(false);
		}
		if (currentStep === Step.MobileVerify) {
			if (mobileVerifyFlowState.step === 1) {
				if (!mobileVerifyFlowState.number || !mobileVerifyFlowState.region) {
					notifyError('Please enter your mobile number to continue');
					setShowValidationErrors(true);
				} else {
					let errorMessage = 'Something went wrong, please contact support';
					setIsLoading(true);
					try {
						const response = await api.userV2.updateProfile({
							cellphone: {
								number: mobileVerifyFlowState.number,
								region: mobileVerifyFlowState.region,
							},
						});
						if (response.status >= 200 && response.status < 300) {
							const { data } = await api.userV2.getProfile();
							const number = data.cellphone && data.cellphone.number;
							setMobileVerifyFlowState(state => ({
								...state,
								step: 2,
								number,
							}));
						} else {
							if (response.data) {
								if (response.data.error) {
									errorMessage = response.data.error.message;
								} else if (response.data[0]) {
									errorMessage = response.data[0];
								}
							}
							notifyError(errorMessage);
						}
					} catch (err) {
						notifyError(errorMessage);
					}
					setIsLoading(false);
				}
				return;
			}
			if (mobileVerifyFlowState.step === 2) {
				if (!mobileVerifyFlowState.otp) {
					notifyError('Please enter your otp to continue');
					setShowValidationErrors(true);
					return;
				} else {
					let errorMessage = 'Something went wrong, please contact support';
					setIsLoading(true);
					try {
						const response = await api.user.verifyNumber(mobileVerifyFlowState.otp);
						setIsLoading(false);
						if (response.status >= 200 && response.status < 300) {
							setMobileVerified(true);
							setMobileVerifyFlowState(initialMobileVerifyState);
						} else {
							if (response.data) {
								if (response.data.error) {
									errorMessage = response.data.error.message;
								} else if (response.data[0]) {
									errorMessage = response.data[0];
								}
							}
							notifyError(errorMessage);
							return;
						}
					} catch (err) {
						notifyError(errorMessage);
						setIsLoading(false);
						return;
					}
				}
			}
		}
		setContainerScroll();
		// TODO: May need to work this depending on staging tests
		if (
			authContainer.state.token &&
			(currentStep === Step.PersonalInfo || currentStep === Step.FundingInfo)
		) {
			setIsLoading(true);
			saveBookingProfile(bookingProfile).then(response => {
				setIsLoading(false);
				setSavedBookingProfile(response.data);

				if (
					calculateStepStatus(
						currentStep,
						response.data,
						leaseTerm,
						moveInDate,
						additionalInformation,
						selectedRoomId,
						firstName,
						lastName,
						email,
						mobileVerified,
						listing ? listing.lease_type : '',
						campusSet,
						additionalInfoSeen,
					) === StepStatus.Incomplete
				) {
					notifyError('Please fill in all required fields');
					setShowValidationErrors(true);
					return;
				}

				if (response.status === 400) {
					notifyError('Please ensure all fields are valid');
					setShowValidationErrors(true);
					return;
				}

				setCurrentStep(stepSequence[currentIndex + 1]);
				setShowValidationErrors(false);
			});
			return;
		}
		setCurrentStep(stepSequence[currentIndex + 1]);
		setShowValidationErrors(false);
	};

	const handlePrivacyCheckboxClick = (): void => {
		setAcceptedPrivacyPolicy(!acceptedPrivacyPolicy);
	};

	const handleMarketingConsentClick = (): void => {
		setAcceptedMarketingCheckbox(prev => !prev);
	};

	const handleStepSelect = (step: Step): void => {
		if (
			currentStep === stepSequence[stepSequence.length - 1] ||
			(stepStatuses[step] === StepStatus.Incomplete &&
				step !== stepSequence.find(step => stepStatuses[step] === StepStatus.Incomplete))
		) {
			return;
		}
		setCurrentStep(step);
	};

	const handleBackToListingClick = (): void => {
		if (currentStep === Step.Success) {
			navigateBackToListing();
			return;
		}
		modalContainer.set(ModalType.CancelEnquiry, {
			navigateBackToListing: navigateBackToListing,
		});
	};

	const handleNoInstitutionFoundClick = () => {
		modalContainer.set(ModalType.NoInstitutionFound, {
			createUserInstitution: createUserInstitution,
		});
	};

	const handleWhyThisInfoClick = () => {
		modalContainer.set(ModalType.enquiryInfo, {
			EnquiryInfoStep: enquiryInfo[currentStep],
		});
	};

	const handleInstitutionFieldChange = (
		change: { [key: string]: any },
		newInstitution?: boolean,
	): void => {
		setBookingProfile(bookingProfile => ({
			...bookingProfile,
			...change,
		}));
		if (newInstitution) {
			setCampusSet(false);
			setCampusOptions([
				{
					label: change.campus.name,
					value: change.campus.id,
				},
			]);
		} else {
			setCampusOptionsFromInstitute(change.institution.name);
		}
	};

	const handleCampusFieldChange = (change: { [key: string]: any }): void => {
		if (change.campus.name == "I'm not sure yet" || change.campus.id == null) {
			change.campus = null;
		}
		handleBookingProfileFieldChange(change);
	};

	const handleBookingProfileFieldChange = (change: { [key: string]: any }): void => {
		setBookingProfile(bookingProfile => ({
			...bookingProfile,
			...change,
		}));
	};

	const handlePersonalInfoFieldChange = (change: { [key: string]: any }): void => {
		if (change.firstName !== undefined) {
			setFirstName(change.firstName);
			return;
		}
		if (change.lastName !== undefined) {
			setLastName(change.lastName);
			return;
		}
		if (change.email !== undefined) {
			setEmail(change.email);
			return;
		}
		setBookingProfile(bookingProfile => ({
			...bookingProfile,
			...change,
		}));
	};

	const handleLeasePeriodChange = (val: any) => {
		setLeaseTerm(Math.round(val));
	};

	const handleLoginClick = (): void => {
		modalContainer.set(ModalType.Login, {
			onLoginSuccess: () => {
				// TODO: Update state correctly
				window.location.reload();
			},
		});
	};

	const centerPanelRef = createRef<HTMLDivElement>();
	const scrollToBottom = (): void => {
		const scrollPanel = centerPanelRef.current;
		const mobileScrollPanel = scrollableContainerRef.current;
		if (!scrollPanel || !mobileScrollPanel) {
			return;
		}
		// Timeout to ensure menu only opens after on menu dropdowns
		setTimeout(function() {
			scrollPanel.scrollTop = scrollPanel.scrollHeight - scrollPanel.clientHeight;
			mobileScrollPanel.scrollTop = mobileScrollPanel.scrollHeight - mobileScrollPanel.clientHeight;
		}, 200);
	};

	const width =
		window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

	let oldScrollTop = 0;

	const updateHeaderScroll = () => {
		if (width > BREAKPOINT_MOBILE_PX) {
			return;
		}

		if (scrollableContainerRef.current!.scrollTop > 100) {
			if (scrollableContainerRef.current!.scrollTop > oldScrollTop) {
				hidingHeader.current!.style.minHeight = `0px`;
				hidingHeader.current!.style.height = `0px`;
				hidingHeader.current!.style.opacity = `0`;
			} else {
				hidingHeader.current!.style.minHeight = mobileHeaderHeight;
				hidingHeader.current!.style.height = mobileHeaderHeight;
				hidingHeader.current!.style.opacity = '1';
			}
			oldScrollTop = scrollableContainerRef.current!.scrollTop;
		}
	};

	const scrollableContainerRef = useRef<HTMLDivElement>(null);
	const hidingHeader = useRef<HTMLDivElement>(null);

	const isLastStep = currentStep === stepSequence[stepSequence.length - 2];
	const canGoBack = currentStep !== stepSequence[0];
	const firstIncompleteStep = stepSequence.find(
		step => stepStatuses[step] === StepStatus.Incomplete,
	);
	return (
		<PageContainer ref={scrollableContainerRef} onScroll={updateHeaderScroll}>
			<Helmet>
				<title>{meta.title}</title>
				<meta name="description" content={meta.desc} />
			</Helmet>
			<HeaderContainer>
				<Header searchable />
			</HeaderContainer>
			<PanelsContainer>
				<LeftPanel
					stepSequence={stepSequence}
					currentStep={currentStep}
					handleStepSelect={handleStepSelect}
					stepStatuses={stepStatuses}
					firstIncompleteStep={firstIncompleteStep}
					handleBackToListingClick={handleBackToListingClick}
					StepStatus={StepStatus}
					stepTitles={stepTitles}
				/>
				<CenterPanel>
					<DesktopOnly>
						<CenterPanelTopBar />
						{currentStep !== Step.Success && <ProgressBar percentage={progressPercentage} />}
					</DesktopOnly>

					<MobileOnly>
						<MobileHeader>
							<CenterPanelTopBar ref={hidingHeader}>
								<Cross onClick={handleBackToListingClick} />
							</CenterPanelTopBar>
							{currentStep !== Step.Success && <ProgressBar percentage={progressPercentage} />}
						</MobileHeader>
						<MobileHeaderSpacing />
					</MobileOnly>

					<CenterPanelContentContainer ref={centerPanelRef}>
						<CenterPanelContent>
							{currentStep === Step.PersonalInfo && (
								<Subscribe to={[authContainer]}>
									{auth => (
										<PersonalInfo
											showValidationErrors={showValidationErrors}
											firstName={firstName}
											lastName={lastName}
											email={email}
											emailReadonly={isEmailReadonly}
											selectedUserType={bookingProfile.occupant_type}
											institutionOptions={institutionOptions}
											campusOptions={campusOptions}
											onCampusSet={setCampusSet}
											selectedInstitution={bookingProfile.institution}
											selectedCampus={bookingProfile.campus}
											selectedOccupation={bookingProfile.occupation}
											showLoginPrompt={!auth.state.token}
											acceptedPrivacyPolicy={acceptedPrivacyPolicy}
											acceptedMarketingCheckbox={acceptedMarketingCheckbox}
											onUserTypeSelection={handleUserTypeSelection}
											onPrivacyCheckboxClick={handlePrivacyCheckboxClick}
											onLoginClick={handleLoginClick}
											onDropDownClick={scrollToBottom}
											onNoInstitutionFoundClick={handleNoInstitutionFoundClick}
											onPersonalInfoFieldChange={handlePersonalInfoFieldChange}
											onBookingProfileFieldChange={handleBookingProfileFieldChange}
											onInstitutionFieldChange={handleInstitutionFieldChange}
											onCampusFieldChange={handleCampusFieldChange}
											onInstitutionSearch={handleInstitutionSearch}
											onMarketingCheckboxClick={handleMarketingConsentClick}
										/>
									)}
								</Subscribe>
							)}
							{currentStep === Step.FundingInfo && listing && (
								<FundingInfo
									rooms={listing.rooms || []}
									listing={listing}
									currency={listing.currency}
									userType={bookingProfile.occupant_type}
									moveInDate={moveInDate}
									selectedRoomId={selectedRoomId}
									leasePeriodMonths={leaseTerm}
									preferredMoveInDate={(listing && listing.availability_date) || ''}
									selectedFundingType={bookingProfile.payment_provider}
									showValidationErrors={showValidationErrors}
									preferredLeasePeriodMonths={
										(listing && listingService.getMonthlyLeaseTerm(listing)) || 0
									}
									absaCheckbox={absaCheckbox}
									onAbsaCheckboxChange={() => setAbsaCheckbox(prev => !prev)}
									onRoomSelect={room => setSelectedRoomId(room.uuid)}
									onDateChange={setMoveInDate}
									onDateElementClick={scrollToBottom}
									onLeasePeriodChange={handleLeasePeriodChange}
									onFundingInfoSelect={handleFundingTypeSelection}
								/>
							)}
							{currentStep === Step.AdditionalInfo && listing && (
								<AdditionalInfo
									text={additionalInformation}
									onTextChange={setAdditionalInformation}
									showValidationErrors={showValidationErrors}
									listing={listing}
									remainingBookingSlots={savedBookingProfile.booking_requests_remaining}
								/>
							)}
							{currentStep === Step.MobileVerify && (
								<VerifyMobile
									state={mobileVerifyFlowState}
									onNumberChange={num => {
										setMobileVerifyFlowState(state => ({
											...state,
											number: num,
										}));
									}}
									onRegionChange={region => {
										setMobileVerifyFlowState(state => ({
											...state,
											region,
										}));
									}}
									onOtpChange={otp => {
										setMobileVerifyFlowState(state => ({
											...state,
											otp,
										}));
									}}
								/>
							)}
							{currentStep === Step.Success &&
								listing &&
								!featureFlagContainer.isEnabled(FeatureFlag.InboxRedirect) && (
									<Success
										history={history}
										listing={listing}
										remainingBookingSlots={savedBookingProfile.booking_requests_remaining}
									/>
								)}
						</CenterPanelContent>
					</CenterPanelContentContainer>
					{currentStep !== Step.Success && (
						<ActionBar>
							<BackButton isDisabled={!canGoBack} onClick={handleBackClick}>
								Back
							</BackButton>
							<Button
								noMargin
								isPink={isLastStep}
								isLoading={isLoading}
								maxWidth={200}
								onClick={handleNextClick}
							>
								{isLastStep ? 'Submit enquiry' : 'Continue'}
							</Button>
						</ActionBar>
					)}
				</CenterPanel>
				<RightPanel enquiryInfoStep={enquiryInfo[currentStep]} match={match} listing={listing} />
			</PanelsContainer>
		</PageContainer>
	);
};

export default Booking;
