import { InfoText } from '../../styled-subcomponents/InfoText';
import { StepTitle } from '../../styled-subcomponents/StepTitle';
import { SubTitle } from '../../styled-subcomponents/SubTitle';
import React from 'react';

import StepOne from './StepOne';
import StepTwo from './StepTwo';

export interface MobileVerifyFlowState {
	step: 1 | 2;
	number: string;
	region: string;
	otp: string;
}

export const initialMobileVerifyState: MobileVerifyFlowState = {
	step: 1,
	number: '',
	region: 'ZA',
	otp: '',
};

interface VerifyMobileProps {
	state: MobileVerifyFlowState;
	onNumberChange(num: string): void;
	onRegionChange(region: string): void;
	onOtpChange(otp: string): void;
}

const VerifyMobile = ({
	state,
	onNumberChange,
	onRegionChange,
	onOtpChange,
}: VerifyMobileProps) => (
	<form>
		<StepTitle>Verification</StepTitle>
		<InfoText>All fields are required</InfoText>
		{state.step === 1 && (
			<StepOne
				number={state.number}
				region={state.region}
				onNumberChange={onNumberChange}
				onRegionChange={onRegionChange}
			/>
		)}
		{state.step === 2 && (
			<StepTwo number={state.number} otp={state.otp} onOtpChange={onOtpChange} />
		)}
	</form>
);

export default VerifyMobile;
