import React from 'react';

import styled from 'styled-components';

// TODO: Replace below Input with lib Input
import Input from 'components/user-profile/shared-components/Input';
import FormElementWrapper from 'components/user-profile/shared-components/FormElementWrapper';
import { CountryCodeSelect } from 'lib/CountryCodeSelect';
import { InfoText } from '../../styled-subcomponents/InfoText';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import MobileNumberInput from 'lib/MobileNumberInput';
import config from 'config/theme';

const { colors } = config;

const StepOne = ({ number, region, onNumberChange, onRegionChange }) => {
	return (
		<StyledCellphoneStepOneSection>
			<StyledForm>
				<InfoText>Please enter your cellphone number below</InfoText>
				<MobileNumberInput
					country={region}
					mobileNumber={number}
					onCountrySelect={onRegionChange}
					onMobileNumberChange={onNumberChange}
				/>
				<ProTipBanner>
					Pro Tip: Sign up with your Cell C number and get 30GB of free data when you move in.
				</ProTipBanner>
			</StyledForm>
		</StyledCellphoneStepOneSection>
	);
};

export default StepOne;

const StyledCellphoneStepOneSection = styled.div`
	height: 100%;
`;

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	width: 100%;
`;

const ProTipBanner = styled.div`
	border-radius: 16px;
	padding: 24px;
	background: ${colors.fadedOrange};
	font-weight: 1000;
	line-height: 1.3;
	margin-top: 16px;
	text-align: center;
`;
